// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledge-hub-tsx": () => import("./../../../src/pages/knowledge-hub.tsx" /* webpackChunkName: "component---src-pages-knowledge-hub-tsx" */),
  "component---src-pages-legal-client-solicitor-engagement-terms-tsx": () => import("./../../../src/pages/legal/client-solicitor-engagement-terms.tsx" /* webpackChunkName: "component---src-pages-legal-client-solicitor-engagement-terms-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-solicitor-terms-tsx": () => import("./../../../src/pages/legal/solicitor-terms.tsx" /* webpackChunkName: "component---src-pages-legal-solicitor-terms-tsx" */),
  "component---src-pages-legal-terms-of-service-tsx": () => import("./../../../src/pages/legal/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-legal-terms-of-service-tsx" */),
  "component---src-pages-onboarding-claim-session-tsx": () => import("./../../../src/pages/onboarding/claim-session.tsx" /* webpackChunkName: "component---src-pages-onboarding-claim-session-tsx" */),
  "component---src-pages-onboarding-complete-tsx": () => import("./../../../src/pages/onboarding/complete.tsx" /* webpackChunkName: "component---src-pages-onboarding-complete-tsx" */),
  "component---src-pages-onboarding-step-1-tsx": () => import("./../../../src/pages/onboarding/step1.tsx" /* webpackChunkName: "component---src-pages-onboarding-step-1-tsx" */),
  "component---src-pages-onboarding-step-2-tsx": () => import("./../../../src/pages/onboarding/step2.tsx" /* webpackChunkName: "component---src-pages-onboarding-step-2-tsx" */),
  "component---src-pages-onboarding-step-3-tsx": () => import("./../../../src/pages/onboarding/step3.tsx" /* webpackChunkName: "component---src-pages-onboarding-step-3-tsx" */),
  "component---src-pages-onboarding-step-4-tsx": () => import("./../../../src/pages/onboarding/step4.tsx" /* webpackChunkName: "component---src-pages-onboarding-step-4-tsx" */),
  "component---src-pages-start-case-tsx": () => import("./../../../src/pages/start/case.tsx" /* webpackChunkName: "component---src-pages-start-case-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

